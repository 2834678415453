<template>
  <div class="container">
    <Header />
    <div class="main">
      <div>
        <div class="hotelname">
          {{ hotel.name }}
        </div>

        <div class="hoteladdress">
          <span class="iconfont icon-iconlocation" />{{ hotel.address }}
        </div>
      </div>

      <div class="pictures">
        <el-carousel indicator-position="outside" height="500px">
          <el-carousel-item v-for="(image, index) in hotel.images" :key="index">
            <el-image fit="cover" :src="image" style="width: 100%; height: 100%;" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <el-divider />
      <div class="hoteldetail">
        <div class="date">
          <el-row type="flex">
            <el-col :span="8" style="text-align: center;">
              <div class="arrival">
                <div>{{ $t('checkin') }}</div>
                <div @click="showArrival" style="margin-top: 10px;">{{ arrival | formatDate }}</div>
                <el-date-picker
                  ref="arrival"
                  v-model="dateRange"
                  type="daterange"
                  style="height: 0; border: none; padding: 0; overflow: hidden;"
                  :picker-options="pickerOptions"
                  @change="onArrivalChanged"
                >
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="8" style="display: flex; flex-direction: row;  align-items: center; justify-content: center;">
              <div class="nights">
                {{ nights }} {{ $t('nights') }}
              </div>
            </el-col>
            <el-col :span="8" style="text-align: center;">
              <div class="departure">
                <div>{{ $t('checkout') }}</div>
                <div @click="showDeparture" style="margin-top: 10px;">{{ departure | formatDate }}</div>
                <el-date-picker
                  ref="departure"
                  v-model="dateRange"
                  type="daterange"
                  style="height: 0; border: none; padding: 0; overflow: hidden;"
                  :picker-options="pickerOptions"
                  @change="onDepartureChanged"
                >
                </el-date-picker>
              </div>
            </el-col>
          </el-row>
        </div>

        <el-divider />

        <div class="description">
          <div class="description-title">
            {{ $t('description') }}
          </div>
          <div class="description-detail">
            {{ hotel.description }}
          </div>
        </div>
        <el-divider />

        <div class="description">
          <div class="description-title">
            {{ $t('facilities') }}
          </div>
        </div>

        <div class="list">
          <el-row>
            <el-col style="padding: 0.5rem 0;" :span="12" v-for="(item ,index) of facilityList" :key="index">
              <span class="facilities">
                <svg-icon :icon-class="facilities[item]" />
                <span>{{ $t(facilities[item]) }}</span>
              </span>
            </el-col>
          </el-row>
        </div>

        <div class="list">
          <el-row type="flex" class="text-center">
            <el-col :span="5" class="middle">
              <span>{{ $t('roomType') }}</span>
            </el-col>
            <el-col :span="6" class="middle">
            </el-col>
            <el-col :span="6" class="middle">
              <span>{{ $t('bed') }}</span>
            </el-col>
            <el-col :span="3" class="middle">
              <span>{{ $t('breakfast') }}</span>
            </el-col>
            <el-col :span="3" class="middle">
              <span>{{ $t('price') }}</span>
            </el-col>
            <el-col :span="2" class="middle">
            </el-col>
          </el-row>
          <div class="list-item" v-for="(roomType, index) of roomTypes" :key="index">
            <el-row type="flex" :gutter="20">
              <el-col :span="5">
                <el-carousel height="140px" class="min-button" :autoplay="false">
                  <el-carousel-item v-for="(image, index) in roomType.images" :key="index">
                    <el-image fit="cover" :src="image" style="width: 100%; height: 100%;" :preview-src-list="roomType.images" />
                  </el-carousel-item>
                </el-carousel>
              </el-col>
              <el-col :span="6" class="middle" style="justify-content: flex-start;">
                <div class="room-type-name" style="color: #E6A23C;">{{ roomType.name }}</div>
              </el-col>
              <el-col :span="6" class="middle">
                <div class="room-type-name">{{ roomType.bed }}</div>
              </el-col>
              <el-col :span="3" class="middle">
                <div class="room-type-name">{{ roomType.breakfast }}</div>
              </el-col>
              <el-col :span="3" class="middle">
                <span class="rate">{{hotel.currency_code}}{{ roomType.rate_amount | formatNumber }}</span>
              </el-col>
              <el-col :span="2" class="middle" style="justify-content: flex-end;">
                <div class="reserve">
                  <el-button type="warning" size="large" @click="reserve(roomType)">{{ $t('reserve') }}</el-button>
                </div>
              </el-col>
            </el-row>

            <el-divider v-if="index < roomTypes.length - 1" />
          </div>

          <van-empty v-if="roomTypes.length < 1" image="search" :description="$t('all rooms sold out')" />
        </div>
        <el-divider />

        <div class="description">
          <div class="description-title">
            {{ $t('cancellationPolicy') }}
          </div>
        </div>

        <ul style="padding-bottom: 10px;">
          <li v-for="(item, index) of cancellationPolicies" :key="index">
            {{ item.description }}
          </li>
        </ul>
      </div>

      <div style="flex: 1;"></div>
      <Footer/>

    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { Notify } from 'vant'
import Header from '@/components/Header'
import { formatDate } from '@/filters'
import Hotel from '@/models/hotel'
import { facilities } from '@/utils'

export default {
  name: 'HotelDetail',
  components: {
    Header
  },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const arrival = new Date(dayjs(this.$route.params.arrival).valueOf())
    const departure = new Date(dayjs(this.$route.params.departure).valueOf())
    return {
      facilities: facilities(),
      showDate: false,
      arrival,
      departure,
      guests: this.$route.params.guests,
      dateRange: [arrival, departure],
      today,
      minDate: today,
      maxDate: today,
      hotel: new Hotel(),
      roomTypes: [],
      cancellationPolicies: []
    }
  },
  computed: {
    nights () {
      if (this.arrival && this.departure) {
        return dayjs(this.departure).diff(dayjs(this.arrival), 'day')
      }

      return 0
    },
    facilityList () {
      if (this.hotel.facilities) {
        return this.hotel.facilities.split(',')
      }

      return []
    },
    pickerOptions () {
      const _this = this
      return {
        disabledDate (time) {
          return time.getTime() > dayjs(_this.maxDate).valueOf() || time.getTime() < dayjs(_this.minDate).valueOf()
        }
      }
    }
  },
  async mounted () {
    try {
      const res = await this.$axios.get(`/invitation/hotel-detail/${this.$route.params.hotelId}/arrival/${this.$route.params.arrival}/departure/${this.$route.params.departure}`)
      this.minDate = new Date(res.data.conference.reservation_begin_date) < this.today ? this.today : new Date(res.data.conference.reservation_begin_date)
      this.maxDate = new Date(res.data.conference.reservation_end_date)
      Object.assign(this.hotel, res.data.hotel)
      this.roomTypes = res.data.roomTypes
      this.cancellationPolicies = res.data.cancellationPolicies
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    showArrival () {
      this.$refs.arrival.focus()
    },
    showDeparture () {
      this.$refs.departure.focus()
    },
    onArrivalChanged (e) {
      this.arrival = e[0]
      this.departure = e[1]
    },
    onDepartureChanged (e) {
      this.arrival = e[0]
      this.departure = e[1]
    },
    reserve (roomType) {
      if (this.nights < 2) {
        Notify(this.$t('reservationNightsMessage'))
      } else {
        this.$router.push({
          name: 'Reservation',
          params: { arrival: formatDate(this.arrival), departure: formatDate(this.departure), guests: this.guests, roomTypeId: roomType.id, hotelId: this.$route.params.hotelId }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  max-width: 1200px;
  margin: 0 auto;
}

.hoteldetail {
  padding-bottom: 20px;
}

.hotelname {
  padding-top: 20px;
  font-size: 2rem;
  color: #333333;
}

.hoteladdress {
  padding: 20px 0;
  color: #5c5c5c;
}

.pictures {
  padding-top: 1px;
}

.date {
  padding: 20px 0;
  .nights {
    border: 1px solid #CCCCCC;
    padding: 5px 10px;
    border-radius: 50%;
    color: #7C7C7C;
  }
}

.description {
  .description-title {
    margin: 1.6rem 0;
    font-size: 1.4rem;
  }
  .description-detail {
    font-size: 1rem;
    color: #5c5c5c;
    line-height: 1.5em;
    word-wrap: break-word;
  }
}

.middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text-center {
  height: 72px;
  background-image: url('../assets/room_type_table_header.jpeg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 20px 0;
}

.room-type-name {
  margin-top: .3rem;
  font-size: 1.2rem;
  color: #5e5a6e;
}

.breakfast {
  color: #5c5c5c;
  margin-top: .3rem;
}

.reserve {
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

ul {
  list-style: inside;
  li {
    color: #5c5c5c;
  }
}

li:not(:first-child) {
  margin-top: 8px;
}

.facilities {
  color: #5c5c5c;

  span:last-child {
    padding-left: 1rem;
    font-size: 1rem;
  }
}
</style>
